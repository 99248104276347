export default {
	data: () => ({
		subscriber: {
			id: 0,
			name: "",
			email: "",
		},
		error: "",
		backUrl: "/subscribers",
		subscriberList: []
	}),
	methods: {
		resetForm() {
			this.$refs.observer.reset();
			this.subscriber = {
				id: 0,
				name: "",
				email: "",

			};
		},
		validateSubscribers() {
			this.subscriber.id > 0 ? this.update() : this.add();
		},
		add() {
			let _vm = this;
			this.axios
				.post("/subscribers", _vm.subscriber)
				.then(function (response) {
					_vm.$router.push(_vm.backUrl);
				})
				.catch(function () { });
		},

		getQueryString() {
			let queryString = "?search=";
			return queryString;
		},
		getDetail() {
			let _vm = this;
			this.axios
				.get("/subscribers/" + this.$route.params.id)
				.then(function (response) {
					_vm.subscriber = response.data.data;
				})
				.catch(function () { });
		},

	},

	mounted() {
		if (this.$route.params.id > 0) {
			this.getDetail();
		}
	}
};
